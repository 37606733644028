import {IAuth} from '../../definition';
import {CHANGE_LANG, LOGIN, LOGOUT, UPDATE_ME} from '../actions';
import {LOCAL_KEY_ISAUTHENTICATED, LOCAL_KEY_PERMS, LOCAL_KEY_USER} from '../../constants';

const defaultAuth: IAuth = {
  isAuthenticated: localStorage.getItem(LOCAL_KEY_ISAUTHENTICATED) === 'true',
  me: JSON.parse(localStorage.getItem(LOCAL_KEY_USER) || 'null'),
};

if(defaultAuth.me)
  defaultAuth.me["perms"] = localStorage.getItem(LOCAL_KEY_PERMS) || [];

export default function reducer(auth: IAuth = defaultAuth, {type, payload}: any): IAuth {
  switch (type) {
    case LOGIN: {
      const state = {
        ...auth,
        isAuthenticated: !payload.isNewPwdRequired,
        me: {
          ...auth.me,
          ...payload,
        },
      };

      const value = payload.isNewPwdRequired ? 'false' : 'true';
      localStorage.setItem(LOCAL_KEY_ISAUTHENTICATED, value);
      localStorage.setItem(LOCAL_KEY_USER, JSON.stringify(state.me));

      return state;
    }

    case CHANGE_LANG: {
      const state = {
        ...auth,
        me: {
          ...auth.me,
          ...payload,
        },
      };

      localStorage.setItem(LOCAL_KEY_USER, JSON.stringify(state.me));

      return state;
    }

    case LOGOUT: {
      localStorage.clear();
      localStorage.removeItem(LOCAL_KEY_USER);
      localStorage.removeItem(LOCAL_KEY_PERMS);
      localStorage.removeItem(LOCAL_KEY_ISAUTHENTICATED);

      return {
        ...auth,
        isAuthenticated: false,
        me: null,
      };
    }

    case UPDATE_ME: {
      const state = {
        ...auth,
        me: {
          ...auth.me,
          ...payload,
        }
      };

      if(payload['perms']) {
        localStorage.setItem(LOCAL_KEY_PERMS, JSON.stringify(payload.perms));
      }

      return state;
    }

    default: {
      return auth;
    }
  }
}
