import {Dispatch} from 'redux';
import {LOGIN, LOGOUT, UPDATE_ME} from '.';
import {IMe} from '../../definition';

export function login(dispatch: Dispatch) {
  return (me: IMe) => (
    dispatch({
      type: LOGIN,
      payload: me,
    })
  );
}

export function logout(dispatch: Dispatch) {
  return () => (
    dispatch({
      type: LOGOUT,
      payload: true,
    })
  );
}

export function updateMe(dispatch: Dispatch) {

  return (me: IMe) => (
    dispatch({
      type: UPDATE_ME,
      payload: me,
    })
  );
}
