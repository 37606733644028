/*
####################################################################################################
# Copyright © éolane 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020
#
####################################################################################################
# HISTORY
# - V0.1 (Vu Do) 2019-11-20:
#   + Util functions
####################################################################################################
*/

export default class X2mUtils {
  /**
   * @param value
   * @returns {value || ''}
   */
  static ensureFieldValueNotNull = (value: any) => {
    return (value === null || value === undefined) ? '' : value;
  };

  static hierarchySort = (arr: any) => {
    let hashArr = {};

    for (let i = 0; i < arr.length; i++) {
      if (hashArr[arr[i].parentId] === undefined) hashArr[arr[i].parentId] = [];
      hashArr[arr[i].parentId].push(arr[i]);
    }

    const rootParent = null;
    const rootLevel = 1;

    return X2mUtils.hierarchySortChildren(hashArr, rootParent, [], rootLevel);
  };

  static scrollToBottom = (htmlId: any) => {
    setTimeout(function () {
      const element: any = document.getElementById(htmlId);
      element.scrollTop = element.scrollHeight;
    }, 100);
  };

  static base64ToHex = (str: string) => {
    const raw = atob(str);
    let result = '';
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16);
      result += (hex.length === 2 ? hex : '0' + hex);
    }
    return result; /*result.toUpperCase();*/
  };

  private static hierarchySortChildren = (hashArr, key, result, level) => {
    if (hashArr[key] === undefined) return;
    const arr = hashArr[key].sort(X2mUtils.hierarchySortFunc);
    for (let i = 0; i < arr.length; i++) {
      arr[i].level = level;
      result.push(arr[i]);
      X2mUtils.hierarchySortChildren(hashArr, arr[i].id, result, level + 1);
    }

    return result;
  };

  private static hierarchySortFunc(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

}
