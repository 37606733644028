/*
####################################################################################################
# Copyright © éolane 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020
#
####################################################################################################
# HISTORY
# - V0.1 (Vu Do) 2019-11-20:
#   + Barcode
####################################################################################################
*/

import _ from 'lodash';

const APP_EUI_LENGTH       = 16;
const DEV_EUI_LENGTH       = 16;
const APP_KEY_LENGTH       = 32;
const DATE_CODE_LENGTH     = 4;
const SERIAL_NUMBER_LENGTH = 9;
const ICCID_MIN_LENGTH     = 19;
const ICCID_MAX_LENGTH     = 22;
const ICCID_START_WITH     = '89';

const SUPPORTED_DEVICE_LENGTH = [16, 32, 45, 77];
const SUPPORTED_ICCID_LENGTH = [19, 20, 21, 22];
const SUPPORTED_DEVICE_AND_ICCID_LENGTH = [16, 32, 45, 77, 19, 20, 21, 22];

const ERROR_MESSAGE = {
  INVALID_DEVICE_LENGTH: 'Invalid length. Supported length: 16, 32, 45, 77.',
  INVALID_ICCID_LENGTH: 'Invalid length. Supported length: 19, 20, 21, 22.',
  INVALID_ICCID: 'Invalid ICCID. It should start with "89".',
};

export default class X2mBarcode {
  static validateBarcode = (barcode) => {
    const barcodeLength = barcode.length;
    let errorMessage = '';

    if (!~SUPPORTED_DEVICE_AND_ICCID_LENGTH.indexOf(barcodeLength)) {
      errorMessage = ERROR_MESSAGE.INVALID_DEVICE_LENGTH;
    } else if (barcodeLength >= ICCID_MIN_LENGTH && barcodeLength <= ICCID_MAX_LENGTH) {
      if (!barcode.startsWith(ICCID_START_WITH)) {
        errorMessage = ERROR_MESSAGE.INVALID_ICCID;
      }
    }

    return {
      error: !!errorMessage,
      errorMessage: errorMessage,
    };
  };

  static validateBarcodeDevice = (barcode) => {
    const barcodeLength = barcode.length;
    let errorMessage = '';

    if (!~SUPPORTED_DEVICE_LENGTH.indexOf(barcodeLength)) {
      errorMessage = ERROR_MESSAGE.INVALID_DEVICE_LENGTH;
    }

    return {
      error: !!errorMessage,
      errorMessage: errorMessage,
    };
  };

  static validateBarcodeICCID = (barcode) => {
    const barcodeLength = barcode.length;
    let errorMessage = '';

    if (!~SUPPORTED_ICCID_LENGTH.indexOf(barcodeLength)) {
      errorMessage = ERROR_MESSAGE.INVALID_ICCID_LENGTH;
    } else if (barcodeLength >= ICCID_MIN_LENGTH && barcodeLength <= ICCID_MAX_LENGTH) {
      if (!barcode.startsWith(ICCID_START_WITH)) {
        errorMessage = ERROR_MESSAGE.INVALID_ICCID;
      }
    }

    return {
      error: !!errorMessage,
      errorMessage: errorMessage,
    };
  };

  static parseBarcode = (barcode) => {
    if (_.isEmpty(barcode)) {
      return null;
    }

    let X2mDeviceIdentifier = {};
    barcode = barcode.toLowerCase();
    const barcodeLength = barcode.length;

    let appEUI = null;
    let devEUI = null;
    let appKey = null;
    let dateCode = null;
    let serialNumber = null;
    let iccid = null;

    if (barcodeLength === 77) {
      let start = 0, end = APP_EUI_LENGTH;

      appEUI       = barcode.substring(start, end); start = end; end += DEV_EUI_LENGTH;
      devEUI       = barcode.substring(start, end); start = end; end += APP_KEY_LENGTH;
      appKey       = barcode.substring(start, end); start = end; end += DATE_CODE_LENGTH;
      dateCode     = barcode.substring(start, end); start = end; end += SERIAL_NUMBER_LENGTH;
      serialNumber = barcode.substring(start, end);
    } else if (barcodeLength === 45) {
      let start = 0, end = APP_EUI_LENGTH;

      appEUI       = barcode.substring(start, end); start = end; end += DEV_EUI_LENGTH;
      devEUI       = barcode.substring(start, end); start = end; end += DATE_CODE_LENGTH;
      dateCode     = barcode.substring(start, end); start = end; end += SERIAL_NUMBER_LENGTH;
      serialNumber = barcode.substring(start, end);
    } else if (barcodeLength === 32) {
      let start = 0, end = APP_EUI_LENGTH;

      appEUI       = barcode.substring(start, end); start = end; end += DEV_EUI_LENGTH;
      devEUI       = barcode.substring(start, end);
    } else if (barcodeLength === 16) {
      let start = 0, end = DEV_EUI_LENGTH;

      devEUI = barcode.substring(start, end);
    } else if (barcodeLength >= ICCID_MIN_LENGTH && barcodeLength <= ICCID_MAX_LENGTH) {
      iccid = barcode;
    } else {
      /* do nothing */
    }

    X2mDeviceIdentifier = {
      appEUI       : isValidAppEUI(appEUI)             ? appEUI       : null,
      devEUI       : isValidDevEUI(devEUI)             ? devEUI       : null,
      appKey       : isValidAppKey(appKey)             ? appKey       : null,
      dateCode     : isValidDateCode(dateCode)         ? dateCode     : null,
      serialNumber : isValidSerialNumber(serialNumber) ? serialNumber : null,
      iccid        : isValidICCID(iccid)               ? iccid        : null,
    };

    return X2mDeviceIdentifier;
  };
}

function isValidAppEUI(appEUI) {
  return isHexChars(appEUI, APP_EUI_LENGTH);
}

function isValidDevEUI(devEUI) {
  return isHexChars(devEUI, DEV_EUI_LENGTH);
}

function isValidAppKey(appKey) {
  return isHexChars(appKey, APP_KEY_LENGTH);
}

function isValidDateCode(dateCode) {
  return isDigits(dateCode, DATE_CODE_LENGTH);
}

function isValidSerialNumber(serialNumber) {
  return isDigits(serialNumber, SERIAL_NUMBER_LENGTH);
}

function isValidICCID(iccid) {
  return isDigits(iccid, ICCID_MIN_LENGTH, ICCID_MAX_LENGTH) && iccid.startsWith(ICCID_START_WITH);
}

function isHexChars(value, minLength = 0, maxLength = minLength) {
  const regex = new RegExp('^([a-f0-9]{' + minLength + ',' + maxLength + '})$');
  return regex.test(value);
}

function isDigits(value, minLength = 0, maxLength = minLength) {
  const regex = new RegExp('^([0-9]{' + minLength + ',' + maxLength + '})$');
  return regex.test(value);
}
