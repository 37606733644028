import {
  ADD_DEVICE_PAIRING,
  REMOVE_DEVICE_PAIRING,
  RESET_DEVICE_PAIRING,
  UPDATE_PAIRING_INFO,
  UPDATE_DEVICE_PAIRING
} from '../types';
import { IPairing } from "../../../definition/Pairing/Pairing";

const saveResult = (device) => {
  return {
    type: ADD_DEVICE_PAIRING,
    device: device,
  };
};

const deleteResult = (device) => {
  return {
    type: REMOVE_DEVICE_PAIRING,
    device: device,
  };
};

const resetResult = () => {
  return {
    type: RESET_DEVICE_PAIRING,
  };
};

const updateResult = (device) => {
  return {
    type: UPDATE_DEVICE_PAIRING,
    device: device,
  };
};

const updatePairingInfoAction = (pairing: IPairing) => {
  return {
    type: UPDATE_PAIRING_INFO,
    pairing: pairing,
  };
};

export const addDevicePairing = (device) => {
  return (dispatch) => {
    dispatch(saveResult(device));
  };
};

export const removeDevicePairing = (device) => {
  return (dispatch) => {
    dispatch(deleteResult(device));
  };
};

export const resetDevicePairing = () => {
  return (dispatch) => {
    dispatch(resetResult());
  };
};

export const updateDevicePairing = (device) => {
  return (dispatch) => {
    dispatch(updateResult(device));
  };
};

export function updatePairingInfo(pairing: IPairing) {
  return (dispatch) => {
    dispatch(updatePairingInfoAction(pairing));
  };
}

