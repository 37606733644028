/*
####################################################################################################
# Copyright © éolane 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020
#
####################################################################################################
# HISTORY
# - V0.1 (Vu Do) 2019-11-20:
#   + Implement authentication by user in cognito
####################################################################################################
*/

import Amplify, {Auth} from 'aws-amplify';
import X2mAWSCognitoConfig from './X2mAWSCognitoConfig';

Amplify.configure({
  Auth: {...X2mAWSCognitoConfig},
});

function Login(username, password, callback) {
  Auth.signIn(username, password)
    .then(user => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
        Auth.completeNewPassword(
          user,               // the Cognito User Object
          password,           // the new password
          // OPTIONAL, the required attributes
          {},
        ).then(user => {
          // at this time the user is logged in if no MFA required
          callback(null, getUserLoginData(user));
        }).catch(err => {
          callback(err);
        });
      } else {
        // other situations
      }

      if (user && user.signInUserSession) {
        callback(null, getUserLoginData(user));
      }
    }).catch(err => {
      callback(err);
    });
}

function getUserLoginData(user) {
  return {
    status: 'OK',
    accessToken: user.getSignInUserSession().getAccessToken().jwtToken,
    idToken: user.getSignInUserSession().getIdToken().jwtToken,
    refreshToken: user.getSignInUserSession().getRefreshToken().token,
  };
}

export default Login;
