import {LOGOUT} from "../actions";
import appReducer from "../reducers/reducer.app";


export default function reducer(state, action) {
  if (action.type === LOGOUT) {
      state = undefined
    }

  return appReducer(state, action);
}
