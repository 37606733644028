export const LOGISTIC_DEVICE_LIST = '/logistic/devices';
export const LOGISTIC_DEVICE_CREATE = '/logistic/devices/create';
export const LOGISTIC_IMPORT = '/logistic/import';
export const LOGISTIC_ASSOCIATE = '/logistic/associate';
export const LOGISTIC_BIB_SHIPMENT = '/logistic/bib-shipment';
export const LOGISTIC_BOB_SHIPMENT = '/logistic/bob-shipment';
export const PDF = '/PDF';

/* Gateway */
export const GATEWAYS = {
  LEU1    : '1',
  LNA3    : '2',
  L4E1    : '3',
};
export const GATEWAYS_LIST = [
  {id: GATEWAYS.LEU1, name: 'Multitech MTCAP-LEU1-868-001A'},
  {id: GATEWAYS.LNA3, name: 'Multitech MTCAP-LNA3-915-001A'},
  {id: GATEWAYS.L4E1, name: 'Multitech MTCAP-L4E1-868-001A'}
];

/* Companies */
export const COMPANIES = {
  EMPTY   : '0',
  VEOLIA  : '2',
  EMSD    : '3'
};
export const COMPANIES_LIST = [
  {id: COMPANIES.EMPTY,   name: ''},
  {id: COMPANIES.VEOLIA,  name: 'Veolia'},
  {id: COMPANIES.EMSD,    name: 'EMSD'},
];

/* Region */
export const REGIONS = {
  EUROPE: '1'
};
export const REGIONS_LIST = [
  {id: REGIONS.EUROPE, name: 'Europe'},
];

/* Operator */
export const OPERATORS = {
  MATOOMA: '1',
};
export const OPERATORS_LIST = [
  {id: OPERATORS.MATOOMA, name: 'Matooma'},
];
