import {IRolesState} from "../../definition";
import { REFRESH_ROLES, SET_CURRENT_ROLE, UPDATE_ROLE, REMOVE_ROLE, ADD_ROLE } from "../actions";

const defaultRoleState: IRolesState = {
  byId: {},
  ids: [],
  ready: false,
  currentId: ''
};

export default function reducer(rolesState: IRolesState = defaultRoleState, {type, payload}: any): IRolesState {
  switch (type) {
    case REFRESH_ROLES: {

      if(!payload) {
        return {
          ...rolesState,
          ready: false,
        };
      } else {
        return {
          ...rolesState,
          ...payload,
          ready: true,
        };
      }
    }

    case UPDATE_ROLE: {
      if(!payload) {
        return rolesState;
      } else {

        const newState = {
          ...rolesState,
        };
        Object.assign(newState.byId[payload.id], payload);
        return newState;
      }
    }

    case SET_CURRENT_ROLE: {
      if(!payload) {
        return rolesState;
      } else {

        return {
          ...rolesState,
          currentId: payload
        };
      }
    }

    case REMOVE_ROLE: {
      if(!payload) {
        return rolesState;
      } else {
        const newState = { ...rolesState };
        const newIds = newState.ids.filter(e => e !== payload); // will return ['A', 'C']
        
        delete newState.byId[payload];
        newState.ids = newIds;

        if(newState.currentId === payload && newState.ids.length > 0) {
          newState.currentId = newState.ids[0];
        }

        return newState;
      }
    }

    case ADD_ROLE: {
      if(!payload) {
        return rolesState;
      } else {
        const newState = { ...rolesState };
        newState.ids.push(payload.id);
        newState.byId[payload.id] = payload;

        return newState;
      }
    }

    default: {
      return rolesState;
    }
  }
}
