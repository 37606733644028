/* auth */
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_ME = 'UPDATE_ME';

/* user */
export const REFRESH_USERS = 'REFRESH_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REMOVE_USER = 'REMOVE_USER';

/* message */
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const SET_CURRENT_MESSAGE = 'SET_CURRENT_MESSAGE';

/* role */
export const REFRESH_ROLES = 'REFRESH_ROLES';
export const ADD_ROLE = 'ADD_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const SET_CURRENT_ROLE = 'SET_CURRENT_ROLE';
export const REMOVE_ROLE = 'REMOVE_ROLE';

/* company */
  export const REFRESH_COMPANIES = 'REFRESH_COMPANIES';
export const ADD_COMPANY = 'ADD_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';

/* group */
export const REFRESH_GROUPS = 'REFRESH_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';

/* screen */
export const TOGGLE_XS_MODE = 'TOGGLE_XS_MODE';
export const CHANGE_LANG = 'CHANGE_LANG';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const ACTIVE_MENU = 'ACTIVE_MENU';
export const CHANGE_HEADING = 'CHANGE_HEADING';
export const CHANGE_BREADCRUMBS = 'CHANGE_BREADCRUMBS';

/* data.devices */
export const REFRESH_DEVICES = 'REFRESH_DEVICES';

/* data.device.pairing */
export const GET_DEVICES_PAIRING = 'GET_DEVICES_PAIRING';
export const ADD_DEVICE_PAIRING = 'ADD_DEVICE_PAIRING';
export const UPDATE_DEVICE_PAIRING = 'UPDATE_DEVICE_PAIRING';
export const REMOVE_DEVICE_PAIRING = 'REMOVE_DEVICE_PAIRING';
export const RESET_DEVICE_PAIRING = 'RESET_DEVICE_PAIRING';
export const UPDATE_PAIRING_INFO = 'UPDATE_PAIRING_INFO';
