/*GenderList
####################################################################################################
# Copyright © éolane 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020
#
####################################################################################################
# HISTORY
# - V0.1 (Vinh PHAM) 20120-08-17:
#   + Define the list of all gender
####################################################################################################
*/

export const GenderList = [
  {'code': 'MALE', 'name': 'LB_MALE', 'defaultAvatar': ''},
  {'code': 'FEMALE', 'name': 'LB_FEMALE','defaultAvatar': ''}
];
