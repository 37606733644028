export const BASE_URL_MAIN = 'https://r4r6rge9xg.execute-api.eu-west-1.amazonaws.com/prod';
export const BASE_URL_FM = 'https://test.eolane-fm.net:8601/x2M/api';

export const REMOTE_URL_LOGIN = '/v1/';
export const REMOTE_URL_BULK_USER = '/v1/bulk/user';
export const REMOTE_URL_USER = '/v1/user';
export const REMOTE_URL_INVITE_USER = '/v1/user/:login/invite';
export const REMOTE_URL_RE_ENABLE_USER = '/v1/user/:login/enable';
export const REMOTE_URL_CHANGE_COMPANY_USER = '/v1/user/:login/change-company';
export const REMOTE_URL_USER_AVATAR = '/v1/user/:login/avatar';
export const REMOTE_URL_COMPANY = '/v1/company';
export const REMOTE_URL_GROUP = '/v1/group';
export const REMOTE_URL_ROLE = '/v1/role';
export const REMOTE_URL_GROUP_AVATAR = '/v1/group/:id/avatar';
export const REMOTE_URL_DEVICE = '/v1/device';
export const REMOTE_URL_TAG = '/v1/tag';
export const REMOTE_URL_DEVICES_GET = '/v1/device';
export const REMOTE_URL_DEVICES_IMPORT = '/v1/deviceImport';
export const REMOTE_URL_LORA_CREDENTIALS = '/v1/loraCredentials';
export const REMOTE_URL_EXECUTION = '/v1/execution';
export const REMOTE_URL_EXECUTION_HISTORY = '/v1/execution-history';
export const REMOTE_URL_UPLOAD_FILE = '/v1/images/signatures/upload';

/* authentication password */
export const REMOTE_URL_FORGOT_PASSWORD = '/v1/auth/password';

/* Me */
export const REMOTE_URL_ME = '/v1/me';
export const REMOTE_URL_PASSWORD = '/v1/me/password';

/* Device */
export const REMOTE_URL_GET_DEVICE_DATA = '/v1/devices/data';

/* Authz */
// export const REMOTE_URL_AUTHZ_ROLES = '/v1/authz/roles';
export const REMOTE_URL_AUTHZ_ROLES = '/v1/authz/roleIds';

export const REMOTE_URL_AUTHZ_USER_PERMS = '/v1/authz/user-perms';
export const REMOTE_URL_AUTHZ_ROLE_PERMS = '/v1/authz/role-perms';
export const REMOTE_URL_AUTHZ_USER_ROLES = '/v1/authz/user-roles';

