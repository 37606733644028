import { IUsersState } from "../../definition/AppState";
import { REFRESH_COMPANIES, REFRESH_USERS, SET_CURRENT_USER, UPDATE_USER, REMOVE_USER, ADD_USER } from "../actions";

const defaultUsersState: IUsersState = {
  byId: {},
  ids: [],
  ready: false,
  currentId: ''
};

export default function reducer(usersState: IUsersState = defaultUsersState, {type, payload}: any): IUsersState {
  switch (type) {
    case REFRESH_USERS: {

      if(!payload) {
        return {
          ...usersState,
          ready: false,
        };
      } else {
        return {
          ...usersState,
          ...payload,
          ready: true,
        };
      }
    }

    /* we need this case to change the company name in each user */
    case REFRESH_COMPANIES: {
      if(payload) {
        const currentById = {...usersState};
        payload.ids.forEach(companyId => {
          if(currentById[companyId])
            currentById[companyId].companyName = payload.byId[companyId].name;
        })
        return {
          ...usersState,
          ...currentById

        };
      } else return {
        ...usersState,
      };
    }

    case UPDATE_USER: {
      if(!payload) {
        return usersState;
      } else {

        const newState = {
          ...usersState,
        };
        if(newState.byId[payload.login]) {
          Object.assign(newState.byId[payload.login], payload);
        } else {
          newState.byId[payload.login] = payload;
        }

        return newState;
      }
    }

    case SET_CURRENT_USER: {
      if(!payload) {
        return usersState;
      } else {

        return {
          ...usersState,
          currentId: payload
        };
      }
    }

    case REMOVE_USER: {
      if(!payload) {
        return usersState;
      } else {
        const newState = { ...usersState };
        const newIds = newState.ids.filter(e => e !== payload); // will return ['A', 'C']
        
        delete newState.byId[payload];
        newState.ids = newIds;

        if(newState.currentId === payload && newState.ids.length > 0) {
          newState.currentId = newState.ids[0];
        }

        return newState;
      }
    }

    case ADD_USER: {
      if(!payload) {
        return usersState;
      } else {
        const newState = { ...usersState };
        newState.ids.push(payload.login);
        newState.byId[payload.login] = payload;

        return newState;
      }
    }

    default: {
      return usersState;
    }
  }
}
