import {Request} from '../../../libs';
import {
  REMOTE_URL_DEVICE,
} from '../../../constants';

const request = new Request('AWS');

export async function getAllDevices(query?: any): Promise<any> {
  const rs = await request.get(REMOTE_URL_DEVICE, query);

  return request.getResponseBody(rs);
}