/*
####################################################################################################
# Copyright © éolane 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020
#
####################################################################################################
# HISTORY
# - V0.1 (Vu Do) 2019-11-20:
#   + A CSV library: parse file to the device list (Locale: France)
####################################################################################################
*/

import papaparse from 'papaparse';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const CSV_COLUMN = {
  DATE_TIME                 : 'Date et heure',
  INTERNAL_CODE             : 'Etiquette carte',
  LARGE_PRODUCT_LABEL       : 'Grande étiquette produit',
  APP_EUI                   : 'APP_EUI',
  DEV_EUI                   : 'DEV_EUI',
  APP_KEY                   : 'APP_KEY',
  REF_PRODUIT               : 'REF PRODUIT',
  PRODUCT_PART_NUMBER       : 'Dossier_définition_et_indices_151H7019',
  SERIAL_NUMBER             : 'Numéro de série',
};

const CSV_COLUMN_LENGTH = {
  DATE_TIME                 : 1,
  INTERNAL_CODE             : 1,
  LARGE_PRODUCT_LABEL       : 1,
  APP_EUI                   : 16,
  DEV_EUI                   : 16,
  APP_KEY                   : 32,
  REF_PRODUIT               : 1,
  PRODUCT_PART_NUMBER       : 1,
  SERIAL_NUMBER             : 9,
};

const DEFAULT_DEVICE_DATA = {};

function X2mCSV() {
}

const self = new X2mCSV();
export default self;

X2mCSV.prototype.convertCSVFileToDevices = (csvFile: any, deviceType: any) => {
  return new Promise((resolve, reject) => {
    papaparse.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      encoding: 'ISO-8859-1',
      complete: function (results) {
        const importedSensors = results.data;
        const validatedDevices: any = [];

        for (let iSensorIndex = 0, numberOfSensor = importedSensors.length; iSensorIndex < numberOfSensor; iSensorIndex++) {
          const sensor = importedSensors[iSensorIndex];
          const sensorDataStatus = checkSensorData(sensor);
          if (!sensorDataStatus.ok) {
            return reject({
              message: sensorDataStatus.message || '[NOK] Invalid sensor data at line ' + iSensorIndex + ': ' + JSON.stringify(sensor),
            });
          }
          validatedDevices.push(convertToDevice(sensor, deviceType));
        }

        resolve(validatedDevices);
      },
      error: reject,
    });
  });
};

function checkSensorData(objSensor) {
  const isValidDevEUI = isValidField(objSensor, CSV_COLUMN.DEV_EUI, CSV_COLUMN_LENGTH.DEV_EUI);
  let message = '';
  message = message || (isValidDevEUI ? '' : 'INVALID_LENGTH_DEVEUI');

  return {
    ok: isValidDevEUI,
    message: message,
  };
}

function convertToDevice(objSensor, deviceType) {
  var device = {
    appEUI             : objSensor[CSV_COLUMN.APP_EUI],
    devEUI             : objSensor[CSV_COLUMN.DEV_EUI],
    appKey             : objSensor[CSV_COLUMN.APP_KEY],
    serialNumber       : objSensor[CSV_COLUMN.SERIAL_NUMBER],
    factoryDate        : moment(objSensor[CSV_COLUMN.DATE_TIME], 'ddd DD MMM YYYY HH mm ss', 'fr'),
    productReference   : objSensor[CSV_COLUMN.REF_PRODUIT],
    productPartNumber  : objSensor[CSV_COLUMN.PRODUCT_PART_NUMBER].replace('PN : ', ''),
    internalCode       : objSensor[CSV_COLUMN.INTERNAL_CODE],
    type               : deviceType,
  };

  _.defaults(device, DEFAULT_DEVICE_DATA);

  return device;
}

function isValidField(object, fieldName, length) {
  return (object[fieldName] && object[fieldName] !== '' && object[fieldName].length === length);
}
