import React from 'react';
import {connect} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {SnackbarProvider} from 'notistack';

import {IAppState} from '../../definition';
import {X2mNotistackContainer} from '../../helpers/X2mNotistack';

const AppDashboard = React.lazy(() => import('../AppDashboard'));
const AppCredentials = React.lazy(() => import('../AppCredentials'));


const notifyStackRef: any = React.createRef();
const onDismissNotifyStack = (key: any) => () => {
  notifyStackRef.current.closeSnackbar(key);
};

function mapStateToProps({auth}: IAppState) {
  return {isAuthenticated: auth.isAuthenticated};
}

export default connect(
  mapStateToProps,
  null,
)(function ({isAuthenticated}: any): React.FunctionComponentElement<any> {
  return (
    <SnackbarProvider
      action={(key) => (
        <IconButton
          color="inherit"
          onClick={onDismissNotifyStack(key)}
        >
          <CloseIcon />
        </IconButton>
      )}
      maxSnack={10}
      ref={notifyStackRef}
    >
      <React.Fragment>
        <X2mNotistackContainer />
        <BrowserRouter>
          {isAuthenticated ? <AppDashboard /> : <AppCredentials />}
        </BrowserRouter>
      </React.Fragment>
    </SnackbarProvider>
  );
});
