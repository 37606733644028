
import {REFRESH_COMPANIES, REFRESH_USERS, SET_CURRENT_USER, UPDATE_USER, REMOVE_USER, ADD_USER} from '.';
import {ICompaniesState, ICompany, IUser, IUsersState} from '../../definition';
import * as UserService from "../../apps/user/services";
import {REMOTE_URL_COMPANY} from "../../constants";
import {Request} from "../../libs";

const request = new Request('AWS');

export const refreshUsers = () => {

  return async (dispatch) => {
    let usersRs: any;
    let companiesRs: any;

    /* First dispatch to notify that we going to clean local data */
    dispatch({
      type: REFRESH_USERS,
    })

    try {
      usersRs = await UserService.getAllUsers();
      if (!usersRs) return;
      companiesRs = await request.get(REMOTE_URL_COMPANY, {});

      if (!companiesRs) return;
    } catch(e) {
      console.error(e);
    }

    const companiesState: ICompaniesState = {
      byId: companiesRs.data.data.reduce((result: any, item: ICompany/*, index, array*/) => {
        const id = item.id || '';
        result[id] = item;

        return result;
      }, {}),
      // @ts-ignore
      ids: companiesRs.data.data.map(company => company.id),
    };

    /* this dispatch for specific Company */
    dispatch({
      type: REFRESH_COMPANIES,
      payload: companiesState,
    });

    const usersState: IUsersState = {
          byId: usersRs.data.reduce((result: any, item: IUser/*, index, array*/) => {
            const id = item.login || '';
            if(item.company && companiesState.byId[item.company]) {
              item.companyName = companiesState.byId[item.company].name;
            }
            result[id] = item;

            return result;
          }, {}),
          // @ts-ignore
          ids: usersRs.data.map(user => user.login),
        };
    /* Second dispatch that we notify the remote data */
    return dispatch({
      type: REFRESH_USERS,
      payload: usersState,
    });
  }
}

export const updateUserState = (user) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER,
      payload: user,
    });
  };
}

export const setCurrentUser = (username) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: username,
    });
  };
}

export const deleteUserState = (username) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_USER,
      payload: username,
    });
  };
}

export const addUserState = (user) => {
  return (dispatch) => {
    dispatch({
      type: ADD_USER,
      payload: user,
    });
  };
}

