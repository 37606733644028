import {REFRESH_COMPANIES} from "../actions";
import {ICompaniesState} from "../../definition/AppState";

const defaultCompaniesState: ICompaniesState = {
  byId: {},
  ids: [],
  ready: false,
};

export default function reducer(companiesState: ICompaniesState = defaultCompaniesState, {type, payload}: any): ICompaniesState {
  switch (type) {
    case REFRESH_COMPANIES: {
      if(!payload) {
        return {
          ...companiesState,
          ready: false,
        };
      } else {
        return {
          ...companiesState,
          ...payload,
          ready: true,
        };
      }
    }

    default: {
      return companiesState;
    }
  }
}
