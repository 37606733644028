import {Request} from '../../../libs';
import {
  REMOTE_URL_AUTHZ_ROLES,
  REMOTE_URL_AUTHZ_USER_PERMS,
  REMOTE_URL_AUTHZ_ROLE_PERMS,
  REMOTE_URL_COMPANY, REMOTE_URL_ROLE
} from '../../../constants';
import {IRightGroup, IRight} from '../../../definition';
const request = new Request('AWS');

export async function getAllRoles(query: object = {}) {
  const result = await request.get(REMOTE_URL_AUTHZ_ROLES, query);

  const res = request.getResponseBody(result);
  const mapR = res.data.map(r => {
    return {
      id: r,
      name: r.split('::').join('-'),
      rightIds: []
    }
  })
  return mapR;
}

export async function getAllRolesForUser(query: object = {}) {
  const result = await request.get(REMOTE_URL_AUTHZ_ROLES, query);

  const res = request.getResponseBody(result);
  const mapR = res.data.map(r => {
    return {
      id: r,
      name: r.split('::').join('-'),
      rightIds: []
    }
  })
  return mapR;
}

export async function getAllPermissions() {
  const result = await request.get(REMOTE_URL_AUTHZ_USER_PERMS, {user: 'system'});

  return groupPermissions(result.data.data);
}

export async function getPermissionsForRole(query: object = {}) {
  const result = await request.get(REMOTE_URL_AUTHZ_USER_PERMS, query);

  // @ts-ignore
  const perms: [] = [...new Set(result.data.data)];
  return perms;
}

/**
 * Update permission for a profile | a role
 * @param query
 * @param data
 */
export async function updatePermissionsForRole(data = {}) {
  const result = await request.patch(REMOTE_URL_AUTHZ_ROLE_PERMS, {}, data);

  return request.getResponseBody(result);
}

function groupPermissions (data) {
  const rightGroups: IRightGroup[] = [];
  const rights: IRight[] = [];
  // @ts-ignore
  const perms: string [] = [...new Set(data)];
  perms.forEach(perm => {
    // perms example data: "global.views.company-menu",

    const permSplits = perm.split('.');
    const index = rightGroups.findIndex(x => x.name === permSplits[0]);

    rights.push({id: perm, name: permSplits.join('-')});

    if(index === -1) { // new
      const rightGroup: IRightGroup = {
        id: permSplits[0],
        name: permSplits[0], // translate here
        groups: {
          applications: permSplits[1] ==='applications' ? [perm] : [],
          actions: permSplits[1] ==='actions' ? [perm] : [],
          views: permSplits[1] ==='views' ? [perm] : [],
        }
      }

      rightGroups.push(rightGroup);
    } else {
      if(rightGroups && rightGroups[index]) {
        let oldG = rightGroups[index].groups = rightGroups[index].groups || {};
        oldG.applications = oldG.applications || [];
        oldG.views = oldG.views || [];
        oldG.actions = oldG.actions || [];

        if(permSplits[1] ==='applications')
          oldG.applications.push(perm);

        if(permSplits[1] ==='actions')
          oldG.actions.push(perm);

        if(permSplits[1] ==='views')
          oldG.views.push(perm);
      }
    }
  })

  return {rightGroups, rights};
}

export async function getAllRolesPaging(query: object = {}) {
  const result = await request.get(REMOTE_URL_ROLE, query);

  return request.getResponseBody(result);
}

export async function createRole(roleData) {
  const result = await request.post(REMOTE_URL_ROLE, {}, roleData);

  return request.getResponseBody(result);
}

export async function updateRole(id: string, roleData) {
  const result = await request.patch(`${REMOTE_URL_ROLE}/${id}`, {}, roleData);

  return request.getResponseBody(result);
}

export async function deleteRole(id: string) {

  const result = await request.delete(`${REMOTE_URL_ROLE}/${id}`, {});

  return request.getResponseBody(result);
}


