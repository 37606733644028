import {
  ADD_DEVICE_PAIRING,
  REMOVE_DEVICE_PAIRING,
  RESET_DEVICE_PAIRING,
  UPDATE_PAIRING_INFO,
  UPDATE_DEVICE_PAIRING
} from '../../actions';
import {updateObject} from '../../utility';
import { IDevice } from "../../../definition/Device";
import * as CONSTANTS from "../../../apps/logistic/constants";
import { IPairing } from "../../../definition/Pairing/Pairing";

const initialState = {
  devices: [],
  pairing: {
    region: CONSTANTS.REGIONS.EUROPE,
    gateway: CONSTANTS.GATEWAYS.LEU1,
    operator: CONSTANTS.OPERATORS.MATOOMA,
    iccid: ''
  }
};

const removeDevice = (state, device: IDevice) => {
  const updatedArray = state.devices.filter(d => d.devEUI !== device.devEUI);
  return updateObject(state, {devices: updatedArray});
};

const updateDevice = (state, device: IDevice) => {
  const index = state.devices.findIndex(d => d.devEUI === device.devEUI);
  if (index !== -1) {
    const updatedArray = [
      ...state.devices
    ];

    updatedArray[index] = {
      ...updatedArray[index],
      ...device
    };
    return updateObject(state, {devices: updatedArray});
  }
  return state;
};

const updatePairingInfo = (state, pairing: IPairing) => {
  const updatedCustomer: IPairing = {
    ...state.pairing,
    ...pairing
  };
  return updateObject(state, {pairing: updatedCustomer});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEVICE_PAIRING:
      return updateObject(state, {devices: state.devices.concat(action.device)});
    case REMOVE_DEVICE_PAIRING :
      return removeDevice(state, action.device);
    case RESET_DEVICE_PAIRING :
      return updateObject(state, {devices: []});
    case UPDATE_DEVICE_PAIRING:
      return updateDevice(state, action.device);
    case UPDATE_PAIRING_INFO:
      return updatePairingInfo(state, action.pairing);
    default:
      break;
  }
  return state;
};

export default reducer;
