import {IRight} from '../../../definition/Profile';

const ApplicationRigths: IRight[] = [
  {id: 'app_1', name: 'Access to movee'},
  {id: 'app_2', name: 'Access to bob'},
  {id: 'app_3', name: 'Access to user'},
  {id: 'app_4', name: 'Access to company'},
  {id: 'app_5', name: 'Access to SNCF'},
  {id: 'app_6', name: 'Access to condor'},
];

const ViewRigths: IRight[] = [
  {id: 'view_1', name: 'Display movee menu'},
  {id: 'view_2', name: 'Display bob menu'},
  {id: 'view_3', name: 'Display user menu'},
  {id: 'view_4', name: 'Display company menu'},
  {id: 'view_5', name: 'Display SNCF menu'},
  {id: 'view_6', name: 'Display logistic menu'},
  {id: 'view_7', name: 'Display biossun menu'},
  {id: 'view_8', name: 'Display OCS2 menu'},
  {id: 'view_9', name: 'Display condor menu'},
  {id: 'view_10', name: 'Display tag menu'},
  {id: 'view_11', name: 'Display profile menu'},
];

const ActionRights: IRight[] = [
  {id: 'act_1', name: 'Create user'},
  {id: 'act_2', name: 'Get user'},
  {id: 'act_3', name: 'Update user'},
  {id: 'act_4', name: 'Delete user'},
  {id: 'act_5', name: 'Create company'},
  {id: 'act_6', name: 'Get company'},
  {id: 'act_7', name: 'Update company'},
  {id: 'act_8', name: 'Delete company'},
  {id: 'act_9', name: 'Create bob'},
  {id: 'act_10', name: 'Get bob'},
  {id: 'act_11', name: 'Update bob'},
  {id: 'act_12', name: 'Delete bob'},
  {id: 'act_13', name: 'Add movee'},
  {id: 'act_14', name: 'Get movee'},
  {id: 'act_15', name: 'Update movee'},
  {id: 'act_16', name: 'Visualize movee'},
  {id: 'act_17', name: 'Get movee event'},
  {id: 'act_18', name: 'Create device'},
];

const Rights = [
  ...ApplicationRigths,
  ...ActionRights,
  ...ViewRigths,
];

export default Rights;