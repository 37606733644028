
import {
  REFRESH_ROLES,
  UPDATE_ROLE,
  SET_CURRENT_ROLE,
  REMOVE_ROLE, ADD_ROLE
} from '.';
import {IProfileSystem, IRolesState} from '../../definition';
import * as RoleService from "../../apps/profile/sevices";

export const refreshRoles = () => {

  return async (dispatch) => {
    let rolesRs: any;

    /* First dispatch to notify that we going to clean local data */
    dispatch({
      type: REFRESH_ROLES,
    })

    try {
      rolesRs = await RoleService.getAllRolesPaging();
    } catch(e) {
      console.log(e);
    }

    if (!rolesRs || !rolesRs.data) return;

    const rolesState: IRolesState = {
          byId: rolesRs.data.reduce((result: any, item: IProfileSystem/*, index, array*/) => {
            result[item.id] = item;

            return result;
          }, {}),
          // @ts-ignore
          ids: rolesRs.data.map(role => role.id),
        };
    /* Second dispatch that we notify the remote data */
    return dispatch({
      type: REFRESH_ROLES,
      payload: rolesState,
    });
  }
}

export const updateRoleState = (role) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ROLE,
      payload: role,
    });
  };
}

export const setCurrentRole = (id) => {
  console.log("setCurrentRole" + id);
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_ROLE,
      payload: id,
    });
  };
}

export const deleteRoleState = (id) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ROLE,
      payload: id,
    });
  };
}

export const addRoleState = (role) => {
  return (dispatch) => {
    dispatch({
      type: ADD_ROLE,
      payload: role,
    });
  };
}

