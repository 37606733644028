/*
####################################################################################################
# Copyright © éolane 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020
#
####################################################################################################
# HISTORY
# - V0.1 (Vu Do) 2019-11-20:
#   + Implement authentication by user in cognito
####################################################################################################
*/

import Amplify, {Auth} from 'aws-amplify';
import {IMe} from '../definition/User';
import X2mAWSCognitoConfig from '../helpers/X2mAWSCognitoConfig';

// const awsConfig = {
//   region: 'eu-west-1',
//   userPoolId: 'eu-west-1_ROuJpV1cU',          // Your user pool id here
//   userPoolWebClientId: '2m80kqe56diq344q6pii193c6m',    // Your client id here
// };

Amplify.configure({
  Auth: {...X2mAWSCognitoConfig},
});

function getUserLoginData(user) {
  // TODO check again
  return {
    username: user.username || null,
    login: user.attributes ? user.attributes.email : user.challengeParam.userAttributes.email,
    status: 'OK',
    accessToken: user.getSignInUserSession().getAccessToken().jwtToken,
    idToken: user.getSignInUserSession().getIdToken().jwtToken,
    refreshToken: user.getSignInUserSession().getRefreshToken().token,
  };
}

export class AwsCognito {
  static login(username: string, password: string, callback: any): any {
    Auth.signIn(username, password)
      .then(user => {
        if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          callback(true, user);
        } else {
          if (user && user.signInUserSession) {
            callback(null, getUserLoginData(user));
          }
        }

      })
      .catch(err => {
        callback(err);
      });
  }

  static forceChangePassword(me: IMe, newPassword: string, callback: any): void {
    Auth.signIn(me.username, me.tempPassword)
      .then(user => {
        if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(
            user,
            newPassword,
            {},
          ).then(user => {
            callback(null, getUserLoginData(user));
          }).catch(err => {
            callback(err);
          });
        } else {
          console.log('You don\'t need to change password!');
          callback(null, getUserLoginData(user));
        }
      })
      .catch(err => {
        callback(err);
      });
  }

  static async forgotPassword(username: string) {
    return Auth.forgotPassword(username);
  }

  static async resetPassword(username: string, code: string, newPassword: string) {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  static changePassword(oldPassword: string, newPassword: string, callback: (err) => void) {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then(() => callback(null))
      .catch(err => callback(err));
  }
}
