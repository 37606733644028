import {combineReducers} from 'redux';
import reducerAuth from './reducer.auth';
import reducerScreen from './reducer.screen';
import reducerCompanies from './reducer.companies';
import reducerGroups from './reducer.groups';
import reducerUsers from './reducer.users';
import reducerRoles from './reducer.roles';
import reducerMessages from './reducer.messages';
import reducerData from './data';

export default combineReducers({
  'auth': reducerAuth,
  'screen': reducerScreen,
  'users': reducerUsers,
  'roles': reducerRoles,
  'companies': reducerCompanies,
  'groups': reducerGroups,
  'data': reducerData,
  'messages': reducerMessages,
});
