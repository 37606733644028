import {REFRESH_GROUPS, ADD_GROUP, UPDATE_GROUP, SET_CURRENT_GROUP, REMOVE_GROUP} from '.';
import {IGroup, IGroupsState} from '../../definition';
import {REMOTE_URL_GROUP} from "../../constants";
import {Request} from "../../libs";
const request = new Request('AWS');

export const refreshGroups = () => {

  return async (dispatch) => {
    let groupsRs;

    /* First dispatch to notify that we going to clean local data */
    dispatch({
      type: REFRESH_GROUPS,
    })

    try {
      groupsRs = await request.get(REMOTE_URL_GROUP, {});
    } catch(e) {
      console.error(e);
    }

    const groupsState: IGroupsState = {
          byId: groupsRs.data.data.reduce((result: any, item: IGroup/*, index, array*/) => {
            const id = item.id || '';
            result[id] = item;

            return result;
          }, {}),
          // @ts-ignore
          ids: groupsRs.data.data.map(group => group.id),
        };
    /* Second dispatch that we notify the remote data */
    return dispatch({
      type: REFRESH_GROUPS,
      payload: groupsState,
    });
  }
}


export const updateGroupState = (group) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_GROUP,
      payload: group,
    });
  };
}

export const setCurrentGroup = (groupId) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_GROUP,
      payload: groupId,
    });
  };
}

export const deleteGroupState = (groupId) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_GROUP,
      payload: groupId,
    });
  };
}


export const addGroupState = (group) => {
  return (dispatch) => {
    dispatch({
      type: ADD_GROUP,
      payload: group,
    });
  };
}
