/*
####################################################################################################
# Copyright © éolane 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020
#
####################################################################################################
# HISTORY
# - V0.1 (Vu Do) 2019-11-20:
#   + Cognito configuration
####################################################################################################
*/

export default {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_ROuJpV1cU',          // Your user pool id here
  userPoolWebClientId: '2m80kqe56diq344q6pii193c6m',    // Your client id here
};
