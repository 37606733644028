
import {ADD_MESSAGE, REFRESH_COMPANIES, REFRESH_DEVICES, REFRESH_USERS, SET_CURRENT_MESSAGE, UPDATE_MESSAGE} from '.';
import * as DeviceService from "../../apps/reunir/services";
import {REMOTE_URL_COMPANY} from "../../constants";
import {ICompaniesState, ICompany, IDevice, IDevicesState, IUser, IUsersState} from "../../definition";
import {IMessage} from "../../definition/Message";

export const refreshDevices = () => {
  return async (dispatch) => {
    let devicesRs: any;

    /* First dispatch to notify that we going to clean local data */
    dispatch({
      type: REFRESH_DEVICES,
    })

    try {
      devicesRs = await DeviceService.getAllDevices();
    } catch(e) {
      console.log(e);
    }

    const devicesState: IDevicesState = {
      byId: devicesRs.data.reduce((result: any, item: IMessage/*, index, array*/) => {
        const id = item.vehicle || '';
        result[id] = item;

        return result;
      }, {}),
      // @ts-ignore
      ids: devicesRs.data.map(dev => dev.vehicle),
    };

    /* Second dispatch that we notify the remote data */
    return dispatch({
      type: REFRESH_DEVICES,
      payload: devicesState,
    });
  }
}

export const addMessageState = (message) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      payload: message,
    });
  };
}

export const updateMessageState = (message) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MESSAGE,
      payload: message,
    });
  };
}

export const setCurrentMessageState = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_MESSAGE,
      payload: id,
    });
  };
}

