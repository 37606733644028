/*
####################################################################################################
# Copyright © éolane 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020
#
####################################################################################################
# HISTORY
# - V0.1 (Vu Do) 2019-12-11:
#   + Cross browser Javascript code to get/set caret position in textarea/input
####################################################################################################
*/

function X2mCaret() {
}

const self = new X2mCaret();

/**
 * @param element document.getElementById();
 * @returns {{start: number, end: number}}
 */
X2mCaret.prototype.getCaretPosition = (element: any) => {
  let start = 0;
  let end = 0;

  if ((document as any).selection) { /* IE < 9 Support */
    element.focus();
    const range = (document as any).selection.createRange();
    const rangelen = range.text.length;
    range.moveStart('character', -element.value.length);

    start = range.text.length - rangelen;
    end = start + rangelen;
  } else if (element.selectionStart || element.selectionStart === '0') { /* IE >= 9 and other browsers */
    start = element.selectionStart;
    end = element.selectionEnd;
  } else {
    start = 0;
    end = 0;
  }

  return {start, end};
};

/**
 * @param element document.getElementById();
 * @param start {Number}
 * @param end {Number}
 */
X2mCaret.prototype.setCaretPosition = (element: any, start: any, end: any = start) => {
  if (element.setSelectionRange) { /* IE >= 9 and other browsers */
    element.focus();
    element.setSelectionRange(start, end);
  } else if (element.createTextRange) { /* IE < 9 */
    var range = element.createTextRange();
    range.collapse(true);
    range.moveEnd('character', end);
    range.moveStart('character', start);
    range.select();
  }
};

export default self;
