import i18nInstance from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import {DEFAULT_LANG} from '../constants';
import {TLang} from '../definition';
// import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

export function initialize(lang: TLang = DEFAULT_LANG) {
  i18nInstance
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
    .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng: lang,
      fallbackLng: lang,
      // debug: process.env.NODE_ENV !== 'production',
        debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      // saveMissing: true,
      backend: {
        //https://github.com/i18next/i18next-xhr-backend
        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        loadPath: '/i18n/{{lng}}/{{ns}}.json',
        // path to post missing resources
        addPath: 'i18n/add/{{lng}}/{{ns}}',
      },
      react: {
        wait: true,
        // useSuspense: false,
      },
    });
}

export {i18nInstance};
