/*
####################################################################################################
# Copyright © éolane 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020
#
####################################################################################################
# HISTORY
# - V0.1 (Vu Do) 2019-11-19:
#   + Implement notification based on Material-UI + notistack
# LINK: https://iamhosseindhv.com/notistack/api
####################################################################################################
*/

import {useSnackbar} from 'notistack';

function X2mNotistack() {
}

const x2mNotistackInstance = new X2mNotistack();

X2mNotistack.prototype.log = (message, options: any = {}) => {
  options.variant = options.variant || 'default';
  internalLog(message, options);
};

X2mNotistack.prototype.success = (message, options: any = {}) => {
  options.variant = options.variant || 'success';
  internalLog(message, options);
};

X2mNotistack.prototype.error = (message, options: any = {}) => {
  options.variant = options.variant || 'error';
  internalLog(message, options);
};

X2mNotistack.prototype.info = (message, options: any = {}) => {
  options.variant = options.variant || 'info';
  internalLog(message, options);
};

X2mNotistack.prototype.warning = (message, options: any = {}) => {
  options.variant = options.variant || 'warning';
  internalLog(message, options);
};

function internalLog(message, options: any = {}) {
  options.autoHideDuration = options.autoHideDuration || 3000;
  options.resumeHideDuration = options.resumeHideDuration || 500;
  options.anchorOrigin = options.anchorOrigin || {
    vertical: 'top',
    horizontal: 'right',
  };

  x2mNotistackInstance.enqueueSnackbar(message, options);
}

/**
 * Retrieve the snackbar methods
 * @link https://iamhosseindhv.com/notistack
 */
const X2mNotistackContainer = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  x2mNotistackInstance.enqueueSnackbar = enqueueSnackbar;
  x2mNotistackInstance.closeSnackbar = closeSnackbar;

  return null;
};

export default x2mNotistackInstance;
export {X2mNotistackContainer};
