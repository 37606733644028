import axios from 'axios';

import { BASE_URL_MAIN, BASE_URL_FM } from '../constants';
import { IRequest } from '../definition/Request';

export enum RequestBaseUrl {
  fm = BASE_URL_FM as any,
  aws = BASE_URL_MAIN as any
}

export class Request {
  constructor(requestInstances: RequestBaseUrl) {
    const baseURL: any = requestInstances;

    this.axiosInstance = axios.create({
      baseURL,
    });
  }

  axiosInstance: any;

  async get(url: string, params?: object, headers: object = {}) {
    return this.makeRequest({
      verb: 'get'.toUpperCase(),
      url: url,
      queryParams: params,
      headers: headers
    });
  }

  async post(url: string, data?: object, params?: object, headers: object = {}) {
    return this.makeRequest({
      verb: 'post'.toUpperCase(),
      url: url,
      params: params,
      body: data || {},
      headers: headers
    });
  }

  async put(url: string, data?: object, params?: object) {
    return this.makeRequest({
      verb: 'put'.toUpperCase(),
      url: url,
      params: params,
      body: data || {},
    });
  }

  async patch(url: string, data?: object, params?: object) {
    return this.makeRequest({
      verb: 'patch'.toUpperCase(),
      url: url,
      params: params,
      body: data || {},
    });
  }

  async del(url: string, data?: object, params?: object) {
    return this.makeRequest({
      verb: 'delete'.toUpperCase(),
      url: url,
      params: params,
      body: data || {},
    });
  }

  private makeRequest(request: IRequest) {

    if (request.headers === undefined) {
      request.headers = {};
    }

    //If the user has not specified an override for Content type the use default
    if (request.headers['Content-Type'] === undefined) {
      request.headers['Content-Type'] = 'application/json';
    }

    //If the user has not specified an override for Accept type the use default
    if (request.headers['Accept'] === undefined) {
      request.headers['Accept'] = 'application/json';
    }

    // request.headers['Access-Control-Allow-Origin'] = '*';
    // request.headers['X-Requested-With'] = 'XMLHttpRequest';

    if (request.body === undefined) {
      request.body = '';
    }

    if (request.queryParams) {
      const queryString = this.buildCanonicalQueryString(request.queryParams);
      if (queryString !== '') {
        request.url += '?' + queryString;
      }
    }

    let simpleHttpRequest = {
      method: request.verb,
      url: request.url,
      crossDomain: true,
      headers: request.headers,
      data: request.body,
    };

    return this.axiosInstance(simpleHttpRequest);
  }

  private buildCanonicalQueryString(queryParams) {
    //Build a properly encoded query string from a QueryParam object
    if (Object.keys(queryParams).length < 1) {
      return '';
    }

    let canonicalQueryString = '';
    for (let property in queryParams) {
      if (queryParams.hasOwnProperty(property)) {
        canonicalQueryString += encodeURIComponent(property) + '=' + encodeURIComponent(queryParams[property]) + '&';
      }
    }

    return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
  }
}

//
// export function setAuth(token: string | null) {
//     axios.defaults.headers.common['Authorization'] = token;
// }
