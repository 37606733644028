import {i18n} from '../../helpers';
import {IBreadcrumb, TLang, IScreen} from '../../definition';
import {ACTIVE_MENU, CHANGE_BREADCRUMBS, CHANGE_HEADING, CHANGE_LANG, TOGGLE_SIDEBAR, TOGGLE_XS_MODE} from '../actions';
import {DEFAULT_LANG, LOCAL_KEY_LANG} from '../../constants';

const lang = localStorage.getItem(LOCAL_KEY_LANG) || DEFAULT_LANG;
const defaultScreen: IScreen = {
  xs: false,
  lang: lang as TLang,
  sidebarOpened: true,
  activeMenu: '',
  heading: '',
  breadcrumbs: [],
};

export default function reducer(screen: IScreen = defaultScreen, {type, payload}: any): IScreen {
  switch (type) {
    case TOGGLE_XS_MODE: {
      const sidebarOpened = payload !== true;

      return {
        ...screen,
        xs: payload,
        sidebarOpened,
      };
    }

    case CHANGE_LANG: {
      localStorage.setItem(LOCAL_KEY_LANG, payload.lang);

      return {
        ...screen,
        ...payload,
      };
    }

    case TOGGLE_SIDEBAR: {
      return {
        ...screen,
        sidebarOpened: !screen.sidebarOpened,
      };
    }

    case ACTIVE_MENU: {
      return {
        ...screen,
        activeMenu: payload,
      };
    }

    case CHANGE_HEADING: {
      return {
        ...screen,
        heading: i18n.i18nInstance.t(payload),
      };
    }

    case CHANGE_BREADCRUMBS: {
      const breadcrumbs = payload.map((breadCrumb: string | IBreadcrumb) => {
        if (typeof breadCrumb === 'string') {
          return i18n.i18nInstance.t(breadCrumb);
        } else {
          return {
            href: breadCrumb.href,
            translation: i18n.i18nInstance.t(breadCrumb.translation),
          };
        }
      });

      return {
        ...screen,
        breadcrumbs,
      };
    }

    default: {
      return screen;
    }
  }
}
