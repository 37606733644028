import {IMessagesState} from "../../definition";
import {ADD_MESSAGE, REFRESH_DEVICES, REFRESH_USERS, SET_CURRENT_MESSAGE, UPDATE_MESSAGE} from "../actions";

const defaultMessageState: IMessagesState = {
  byId: {
    // 398: {"pendingMessages":17,"id":43129, "date":"2021-08-16T08:33:24.000Z","service":"/enove/notification/gps","policy":1,"size":252,"status":1,"reserved":33041,"version":0,"type":8,"senderId":31,"recipientId":0,"network":3,"time":"2021-08-16T10:33:24.097+02:00","latitude":45.346878000000004,"longitude":5.578787833333333,"speed":0,"vehicle":398},
  },
  ids: [
    // '2021-08-16T08:33:24.000Z'
  ],
  ready: false,
  currentId: -1
};

export default function reducer(messageState: IMessagesState = defaultMessageState, {type, payload}: any): IMessagesState {
  switch (type) {

    case REFRESH_DEVICES: {

      if(!payload) {
        return {
          ...messageState,
          ready: false,
        };
      } else {
        return {
          ...messageState,
          ...payload,
          ready: true,
        };
      }
    }

    case ADD_MESSAGE: {
      if(!payload) {
        return messageState;
      } else {
        const newState = { ...messageState };
        newState.ids.push(payload.vehicle);
        newState.byId[payload.vehicle] = payload;

        return newState;
      }
    }

    case UPDATE_MESSAGE: {
      if(!payload) {
        return messageState;
      } else {
        const newState = { ...messageState };
        newState.byId[payload.vehicle] = payload;
        return newState;
      }
    }

    case SET_CURRENT_MESSAGE: {
      if(!payload) {
        return messageState;
      } else {

        return {
          ...messageState,
          currentId: payload
        };
      }
    }

    default: {
      return messageState;
    }
  }
}
