import axios, {AxiosInstance, AxiosPromise, AxiosRequestConfig} from 'axios';
import {BASE_URL_FM, BASE_URL_MAIN, LOCAL_KEY_USER} from '../constants';
import {IMe} from '../definition/User';
import x2mNotistackInstance from '../helpers/X2mNotistack';
import store from '../redux/store';
import {LOGOUT} from '../redux/actions';


interface IInstance {
  AWS: string;
  FM: string;
}

type TInstance = keyof IInstance;

const instances: IInstance = {
  AWS: BASE_URL_MAIN,
  FM: BASE_URL_FM,
};

export class Request {
  private static instances = new Map<TInstance, Request>();
  private readonly axiosInstance: AxiosInstance = axios;

  constructor(instanceType: TInstance) {
    const me: null | IMe = JSON.parse(localStorage.getItem(LOCAL_KEY_USER) || 'null');
    const headers: any = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    if (me) {
      headers.Authorization = 'Bearer ' + me.accessToken;
      headers.login = me.login;
    }

    if (Request.instances.has(instanceType)) {
      const instance = Request.instances.get(instanceType);

      if (instance instanceof Request) {
        instance.defaults({headers});

        return instance;
      }
    }

    this.axiosInstance = axios.create({
      baseURL: instances[instanceType],
      headers,
    });

    Request.instances.set(instanceType, this);
  }

  get(url: string, query: object = {}): AxiosPromise {
    return this.send({
      method: 'get',
      url,
      params: query,
    });
  }

  post(url: string, query: object = {}, data: object, headers: any = {}): AxiosPromise {
    return this.send({
      method: 'post',
      url,
      params: query,
      data,
      headers,
    });
  }

  patch(url: string, query: object = {}, data: object): AxiosPromise {
    return this.send({
      method: 'patch',
      url: url,
      params: query,
      data,
    });
  }


  delete(url: string, query: object = {}, data?: object): AxiosPromise {
    return this.send({
      method: 'delete',
      url: url,
      params: query,
      data,
    });
  }

  head(url: string, query: object = {}): AxiosPromise {
    return this.send({
      method: 'head',
      url,
      params: query,
    });
  }

  async send(options: AxiosRequestConfig) {
    const params = {
      ...options,
      headers: {
        ...this.axiosInstance.defaults.headers,
        ...options.headers,
      },
    };

    for (const header in params.headers) {
      if (params.headers.hasOwnProperty(header)) {
        if (params.headers[header] === undefined) {
          delete params.headers[header];
        }
      }
    }

    let resp;
    try {
      resp = await this.axiosInstance(params);
    }
    catch (e) {
      if(e?.message?.indexOf('401') != -1) {
        x2mNotistackInstance.error('Session expired, please login again');
        store.dispatch({
          type: LOGOUT,
          payload: true,
        })
      }
    }
    return resp;
  }

  defaults(config: AxiosRequestConfig) {
    this.axiosInstance.defaults = {
      ...this.axiosInstance.defaults,
      ...config,
    };
  }


  getResponseBody(response) {
    if (!response || !response.data) {
      return {};
    }

    return response.data;
  }

  getResponseBodyData(response) {
    const body = this.getResponseBody(response);

    return body.data;
  }
}
