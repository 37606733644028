import {Request} from '../../../libs';
import {
  REMOTE_URL_CHANGE_COMPANY_USER,
  REMOTE_URL_INVITE_USER,
  REMOTE_URL_ME,
  REMOTE_URL_RE_ENABLE_USER,
  REMOTE_URL_USER, REMOTE_URL_USER_AVATAR
} from '../../../constants';

let request = new Request('AWS');

function convertDynamoUser(dynamoUser: any) {
  const user = {
    ...dynamoUser,
  };

  if (user.landline) {
    const landline = user.landline.split(' ');
    user.lanelineDialCode = landline[0];
    user.landline = landline[1];
  }

  if (user.mobile) {
    const mobile = user.mobile.split(' ');
    user.mobileDialCode = mobile[0];
    user.mobile = mobile[1];
  }

  return user;
}

export async function getAllUsers(query?: any): Promise<any> {
  const rs = await request.get(REMOTE_URL_USER, query);

  return request.getResponseBody(rs);
}

export async function getUserByLogin(login: string) {
  const resp = await request.get(`${REMOTE_URL_USER}/${login}`, {});
  let user = request.getResponseBodyData(resp);

  if (user) {
    user = convertDynamoUser(user);
    return user;
  } else {
    throw new Error('User not found: ' + login);
  }

}

export async function createUser(userData) {
  return request.post(REMOTE_URL_USER, {}, userData);
}

export async function updateUser(username: string, userData) {
  return request.patch(`${REMOTE_URL_USER}/${username}`, {}, userData);
}

export async function deleteUser(company: string, username: string, hard = false) {
  return request.delete(`${REMOTE_URL_USER}/${username}`, {hard, company});
}

export async function inviteUser(login: string) {
  return request.get(REMOTE_URL_INVITE_USER.replace(':login', login), {});
}

export async function reEnableUser(login: string) {
  return request.patch(REMOTE_URL_RE_ENABLE_USER.replace(':login', login), {}, {});
}

export async function changeCompanyUser(login: string, userData) {
  return request.patch(REMOTE_URL_CHANGE_COMPANY_USER.replace(':login', login), {}, userData);
}

export async function isExistedLogin(login: string) {
  let data;

  try {
    data = await request.head(`${REMOTE_URL_USER}/${login}`, {});
  } catch (e) {
    console.log(e);
  }

  console.log(data);
  return !!data;
}

export function getPhonesInForm(formData: any) {
  const data = {...formData};
  let landline: any = null;
  let mobile: any = null;

  if (data.lanelineDialCode && data.landline) {
    landline = `${data.lanelineDialCode} ${data.landline}`;
  }

  if (data.mobileDialCode && data.mobile) {
    mobile = `${data.mobileDialCode} ${data.mobile}`;
  }

  return {
    landline,
    mobile,
  };
}

export async function getMe() {
  request = new Request('AWS');
  const resp = await request.get(`${REMOTE_URL_ME}`, {});
  let user = request.getResponseBodyData(resp);

  if (user) {
    user = convertDynamoUser(user);
    return user;
  } else {
    throw new Error('Error when getting user');
  }
}

export async function updateMe(userData) {
  return request.patch(`${REMOTE_URL_ME}`, {}, userData);
}

export async function deleteUserAvatar(username: string, company: {}) {
  return request.delete(REMOTE_URL_USER_AVATAR.replace(':login', username), company);
}
