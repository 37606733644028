export const COGNITO_USER_STATUSES = {
  'UNCONFIRMED'          : 'UNCONFIRMED',
  'CONFIRMED'            : 'CONFIRMED',
  'ARCHIVED'             : 'ARCHIVED',
  'COMPROMISED'          : 'COMPROMISED',
  'UNKNOWN'              : 'UNKNOWN',
  'RESET_REQUIRED'       : 'RESET_REQUIRED',
  'FORCE_CHANGE_PASSWORD': 'FORCE_CHANGE_PASSWORD',
};

export const COGNITO_ERROR_CODES = {
  'UserNotFoundException' : 'UserNotFoundException',
  'NotAuthorizedException': 'NotAuthorizedException'
};
