import {REFRESH_COMPANIES} from '.';
import {ICompaniesState, ICompany} from '../../definition';
import {REMOTE_URL_COMPANY} from "../../constants";
import {Request} from "../../libs";
const request = new Request('AWS');
export const refreshCompanies = () => {

  return async (dispatch) => {
    let companiesRs;

    /* First dispatch to notify that we going to clean local data */
    dispatch({
      type: REFRESH_COMPANIES,
    })

    try {
      companiesRs = await request.get(REMOTE_URL_COMPANY, {});
      if (!companiesRs) return;
    } catch(e) {
      console.error(e);
    }

    const companiesState: ICompaniesState = {
          byId: companiesRs.data.data.reduce((result: any, item: ICompany/*, index, array*/) => {
            const id = item.id || '';
            result[id] = item;

            return result;
          }, {}),
          // @ts-ignore
          ids: companiesRs.data.data.map(company => company.id),
        };
    /* Second dispatch that we notify the remote data */
    return dispatch({
      type: REFRESH_COMPANIES,
      payload: companiesState,
    });
  }
}
