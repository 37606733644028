import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@material-ui/styles';

import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import store from './redux/store';
import Cover from './components/Cover';
import {i18n} from './helpers';
import theme from './theme';

const state = store.getState();

i18n.initialize(state.screen.lang);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<Cover />}>
        <Provider store={store}>
          <App />
        </Provider>
      </React.Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();


