import {Dispatch} from 'redux';
import {ACTIVE_MENU, CHANGE_BREADCRUMBS, CHANGE_HEADING, CHANGE_LANG, TOGGLE_SIDEBAR, TOGGLE_XS_MODE} from '.';
import {TLang} from '../../definition';


export function toggleXsMode(dispatch: Dispatch) {
  return (xsMode: boolean) => (
    dispatch({
      type: TOGGLE_XS_MODE,
      payload: xsMode,
    })
  );
}

export function changeLang(dispatch: Dispatch) {
  return (lang: TLang) => (
    dispatch({
      type: CHANGE_LANG,
      payload: {lang},
    })
  );
}

export function toggleSideBar(dispatch: Dispatch) {
  return () => (
    dispatch({
      type: TOGGLE_SIDEBAR,
      payload: null,
    })
  );
}


export function activeDashboardMenu(dispatch: Dispatch) {
  return (href: string) => {
    dispatch({
      type: ACTIVE_MENU,
      payload: href,
    });
  };
}

export function changeHeading(dispatch: Dispatch) {
  return (heading: string) => {
    dispatch({
      type: CHANGE_HEADING,
      payload: heading,
    });
  };
}

export function changeBreadcrumbs(dispatch: Dispatch) {
  return (breadcrumbs: string[]) => {
    dispatch({
      type: CHANGE_BREADCRUMBS,
      payload: breadcrumbs,
    });
  };
}
