import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers/reducer.root';
import {logger} from './middlewares/logger';

const IsProd = process.env.NODE_ENV === 'production';

/* redux-devtools-extension: https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup */
const windowAsAny: any = window;
const composeEnhancers = IsProd ? compose : windowAsAny.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware: any[] = [];

if (!IsProd) {
  middleware.push(logger);
}

export default createStore(
  reducers,
  {},
  composeEnhancers(
    applyMiddleware(...middleware, thunk),
  ),
);
