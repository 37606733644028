import {REFRESH_GROUPS, ADD_GROUP, UPDATE_GROUP, SET_CURRENT_GROUP, REMOVE_GROUP} from "../actions";
import {IGroupsState} from "../../definition";

const defaultGroupsState: IGroupsState = {
  byId: {},
  ids: [],
  ready: false,
  currentId: ''
};

export default function reducer(groupsState: IGroupsState = defaultGroupsState, {type, payload}: any): IGroupsState {
  switch (type) {
    case REFRESH_GROUPS: {
      if (!payload) {
        return {
          ...groupsState,
          ready: false,
        };
      } else {
        return {
          ...groupsState,
          ...payload,
          ready: true,
        };
      }
    }

    case UPDATE_GROUP: {
      if (!payload) {
        return groupsState;
      } else {

        const newState = {
          ...groupsState,
        };
        Object.assign(newState.byId[payload.id], payload);
        return newState;
      }
    }

    case SET_CURRENT_GROUP: {
      return {
        ...groupsState,
        currentId: payload
      };
    }

    case REMOVE_GROUP: {
      if (!payload) {
        return groupsState;
      } else {
        const newState = {...groupsState};
        const newIds = newState.ids.filter(e => e !== payload); // will return ['A', 'C']

        delete newState.byId[payload];
        newState.ids = newIds;

        if (newState.currentId === payload && newState.ids.length > 0) {
          newState.currentId = newState.ids[0];
        }

        return newState;
      }
    }

    case ADD_GROUP: {
      if (!payload) {
        return groupsState;
      } else {
        const newState = {...groupsState};
        newState.ids.push(payload.id);
        newState.byId[payload.id] = payload;

        return newState;
      }
    }

    default: {
      return groupsState;
    }
  }
}
