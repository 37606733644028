import {IDevicesState} from '../../../definition';
import {GET_DEVICES_PAIRING} from '../../actions';

const defaultDevicesState: IDevicesState = {
  byId: {},
  ids: [],
  ready: false,
};

export default function reducer(devicesState: IDevicesState = defaultDevicesState, {type, payload}: any): IDevicesState {
  switch (type) {
    case GET_DEVICES_PAIRING: {
      return {
        ...devicesState,
        ...payload,
        ready: true,
      };
    }

    default: {
      return devicesState;
    }
  }
}
